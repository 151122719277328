// import React, { useEffect } from 'react';
// import {
//   Navigate,
//   RouterProvider,
//   createBrowserRouter,
//   useLocation,
// } from "react-router-dom";

// import { GlobalStyle } from "_theme/global";
// import { ThemeProvider } from "styled-components";
// import { darkTheme, lightTheme } from "_theme/colors";

// import { DashboardLayout, UserLayout } from "_components/organisms";
// import { initializeAnalytics, logPageView } from './analytics';
// import Home from "_pages/home/home";
// import Login from "_pages/user/login/login";
// import About from "_pages/user/about/about";
// import Register from "_pages/user/register/register";
// import Services from "_pages/user/services/services";
// import Rgpd from "_pages/user/rgpd-terms-cookies/rgpd/rgpd";
// import Terms from "_pages/user/rgpd-terms-cookies/terms/terms";
// import Cookies from "_pages/user/rgpd-terms-cookies/cookies/cookies";
// import Contacts from "_pages/user/contacts/contacts";
// import HealthAgreements from "_pages/user/health- agreements/health- agreements";
// import Recruit from "_pages/user/recruit/recruit";



// import Faq from "_pages/user/faq/faq";

// import useTheme from "_theme/useTheme";
// import { ThemeContext } from "_context/themeContext";


// import Staff from "_pages/user/staff/staff";

// import Profile from "_pages/user/profile/profile";

// import UnderConstruction from "_pages/user/under-construction/under-construction";
// import Calculadora from "_pages/user/calculadora/calculadora";



// function App() {


//   useEffect(() => {
//     initializeAnalytics();
//     logPageView();
//   }, []);

 

//   const router = createBrowserRouter([
    
//     {
//       //rotas publicas

//       path: "/",
//       element: <UserLayout />,
//       children: [
//         { path: "/", element: <Home /> },
//         { path: "/login", element: <UnderConstruction /> },
//         { path: "/registry", element: <Register /> },
//         { path: "/about", element: <About /> },
//         { path: "/services", element: <Services /> },
//         { path: "/staff", element: <Staff /> },
//         { path: "/calculator", element: <Calculadora /> },
//         {
//           path: "/staff/:id",
//           element: <Profile />,
//         },
//         { path: "/agreements", element: <HealthAgreements /> },
//         { path: "/contacts", element: <Contacts /> },
//         { path: "/recruit", element: <Recruit /> },
//         { path: "/rgpd", element: <Rgpd /> },
//         { path: "/terms", element: <Terms /> },
//         { path: "/cookies", element: <Cookies /> },
//         { path: "/faq", element: <Faq /> },
//       ],
//     },
//     { path: "*", element: <Navigate to="/" /> },
//   ]);

//   const { theme, isDarkTheme, toggleTheme } = useTheme();

//   return (
//     <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
     
//       <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
//         <GlobalStyle />
//         <RouterProvider router={router} />
//         <RouteTracker />
//       </ThemeProvider>
//     </ThemeContext.Provider>
//   );
// }

// export default App;
import React, { useEffect } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import GoogleAnalytics from '../src/GoogleAnalytics';
import { GlobalStyle } from "_theme/global";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "_theme/colors";

import { DashboardLayout, UserLayout } from "_components/organisms";
import { initializeAnalytics, logPageView } from './analytics';
import Home from "_pages/home/home";
import Login from "_pages/user/login/login";
import About from "_pages/user/about/about";
import Register from "_pages/user/register/register";
import Services from "_pages/user/services/services";
import Rgpd from "_pages/user/rgpd-terms-cookies/rgpd/rgpd";
import Terms from "_pages/user/rgpd-terms-cookies/terms/terms";
import Cookies from "_pages/user/rgpd-terms-cookies/cookies/cookies";
import Contacts from "_pages/user/contacts/contacts";
import HealthAgreements from "_pages/user/health- agreements/health- agreements";
import Recruit from "_pages/user/recruit/recruit";
import Faq from "_pages/user/faq/faq";
import useTheme from "_theme/useTheme";
import { ThemeContext } from "_context/themeContext";
import Staff from "_pages/user/staff/staff";
import Profile from "_pages/user/profile/profile";
import UnderConstruction from "_pages/user/under-construction/under-construction";
import Calculadora from "_pages/user/calculadora/calculadora";
function RouteTracker() {
  const location = useLocation();
  
  useEffect(() => {
    window.gtag('config', 'G-GHC3XQF03N', {
      page_path: location.pathname + location.search,
    });
  }, [location]);
  
  return null;
}

function App() {
  // useEffect(() => {
  //   initializeAnalytics();
  //   logPageView();
  // }, []);

  const router = createBrowserRouter([
    {
      //rotas publicas
      path: "/",
      element: <UserLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/login", element: <UnderConstruction /> },
        { path: "/registry", element: <Register /> },
        { path: "/about", element: <About /> },
        { path: "/services", element: <Services /> },
        { path: "/staff", element: <Staff /> },
        { path: "/calculator", element: <Calculadora /> },
        {
          path: "/staff/:id",
          element: <Profile />,
        },
        { path: "/agreements", element: <HealthAgreements /> },
        { path: "/contacts", element: <Contacts /> },
        { path: "/recruit", element: <Recruit /> },
        { path: "/rgpd", element: <Rgpd /> },
        { path: "/terms", element: <Terms /> },
        { path: "/cookies", element: <Cookies /> },
        { path: "/faq", element: <Faq /> },
      ],
    },
    { path: "*", element: <Navigate to="/" /> },
  ]);

  const { theme, isDarkTheme, toggleTheme } = useTheme();

  return (
    <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <GlobalStyle />
        <GoogleAnalytics />
        <RouterProvider router={router}>
          <RouteTracker />
        </RouterProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
