import React from "react";
import { RecruitWrapper } from "./recruit.style";
import { Button, FileButton } from "_components/atoms";
import { Link } from "react-router-dom";

const Recruit = () => {
  return (
    <RecruitWrapper>
      <div className="recruit-container">
        <div className="left-container"></div>
        <form className="right-container">
          <h1>Trabalha Connosco</h1>
          <div className="input-container">
          <div className="email-container">
            <input type="text" placeholder="Nome" />
            <input type="email" placeholder="Email" />
          </div>
          <div className="contact-container">
            <input type="text" placeholder="Contacto" />
            <input type="text" placeholder="Localidade" />
          </div>
          </div>
          <textarea
            name="textarea"
            id="textarea"
            cols="30"
            rows="10"
          ></textarea>
            <div className="file-button"><FileButton>Adicionar Ficheiro</FileButton></div>
            
            <div className="terms">
                <input type="checkbox" name="Terms" id="terms" />
                <p>Aceito os <Link className="terms-link" to="/terms">Termos e condições.</Link> </p>
            </div>
            <div className="send-button">
                       <Button>Candidatar</Button>
            </div>
     
            
        </form>
      </div>
    </RecruitWrapper>
  );
};

export default Recruit;
