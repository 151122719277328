import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

const Dropdown = styled.div`
  cursor: pointer;

`;

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.5rem; */
  /* background: ${({ theme }) => theme.background}; */
  /* border: 2px solid ${colors.primary_2}; */
  border-radius: 5px;
  cursor: pointer;
  position: relative;
font-size: 13px;
font-weight: 700;
  color: ${colors.text_muted};
  img{
    height: 100%;
  }

  
  ${screens.lg} {
      display: none;
    }
  

  &::after {
    content: "";
    /* border: solid  ${({ theme }) => theme.text}; */
    border: solid  ${colors.text_muted};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-left: 10px;
    transition: transform 0.3s ease;

  }

  ${({ isOpen }) => isOpen && `
    &::after {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  `
  }
`;

const DropdownMenu = styled.div`
  display: block;
  /* transform: ${({ isOpen }) => (isOpen ? 'translateX(-78px)' : 'translateX(100px)')}; */
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0px)' : 'translateY(-150px)')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  position: absolute;
  float: left;
  background: ${({ theme }) => theme.background};
  /* border: 2px solid ${colors.text_muted}; */
  box-shadow: ${colors.box_shadow};
  border-radius: 5px;
  width: fit-content;
  overflow: hidden;
  margin-top: .5rem;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10
  ;

  div {
    display: flex;
    align-items: center;
    padding: .5rem;
    &:hover {
      background-color: #f2f2f2;
    }
    img{
      width: 2rem;
    }

  }

  ${screens.lg} {
    margin-top: 1rem;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(-80px)' : 'translateX(-25px)')};
    }
`;



export { Dropdown, DropdownMenu, DropdownButton };
