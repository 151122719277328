import React from "react";
import { ProfileCardWrapper, ProfileTemplate } from "./profile-card.style";
import { Link } from "react-router-dom";
// import profile from "_assets/img/temp/stock_avatar_she 05.png";
import Button from "../buttons/button/button";

const ProfileCard = (props) => {
  const photo = props.photo;
  const imagePath = "/assets/img/profile/" + photo;
  
  return (
    <ProfileCardWrapper>
      <Link to={`/staff/${props.id}`}>
        <ProfileTemplate>
          <div className="staff-img">
            {/* Aqui você iria querer substituir o 'profile' pela imagem real do membro da equipe, se disponível */}
            <img src={imagePath} loading="lazy" alt={`Perfil de ${props.title}`} />
          </div>
          <div className="staff-body">
            <div className="staff-info">
              <h3>{props.title}</h3>
              <p>{props.speciality}</p>
              <p>{props.location}</p>
              <p>{props.rating}</p>
            </div>
            <div className="buttons">
              <Button>Perfil Médico</Button>
              <Button>Marcar Consulta</Button>
            </div>
          </div>
        </ProfileTemplate>
      </Link>
    </ProfileCardWrapper>
  );
};

export default ProfileCard;
