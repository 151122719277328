import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

axios.interceptors.request.use((config) => {
  const jwtToken = localStorage.getItem("jwtToken");
  if (jwtToken) {
    config.headers["Authorization"] = `Bearer ${jwtToken}`;
  }
  return config;
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      try {
        const userData = JSON.parse(loggedInUser);
        setUser(userData);
      } catch (error) {
        console.error("Erro ao analisar os dados do usuário: ", error);

        localStorage.removeItem("user");
      }
    }
  }, []);
  
  const login = async (email, password) => {
    try {
      const response = await axios.post("http://localhost:3000/login", {
        email,
        password,
      });

      const { user, token } = response.data;
      if (user && token) {
        setUser(user);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("jwtToken", token);
      } else {
        throw new Error("Usuário ou token não encontrado na resposta.");
      }
    } catch (error) {
      console.error("Erro no login", error);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("jwtToken");
  };

  const value = { user, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
