import { colors } from "_theme/colors";
import styled from "styled-components";

const DropDownMenuWrapper = styled.div`
width: 100%;
`;
const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
`;

const DropdownHeader = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.form};
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; 

  .icon-arrow {
    content: "";
    border: solid ${({ theme }) => theme.text};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    transition: transform 0.3s ease;
  }

  .icon-arrow.open {
    transform: translateY(-50%) rotate(-135deg);
  }

  .icon-clear {
    display: none; /* Initially hidden */
  }
`;

const DropdownListContainer = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 150px; /* Limite de altura com scroll */
  overflow-y: auto; /* Scroll vertical se necessário */
  background: #ffffff;
  border: 1px solid #ddd;
`;

const DropdownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 1px solid #ddd;
  box-sizing: border-box;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled.li`
  list-style: none;
  padding: 10px;
  cursor: pointer;
  background: ${({ theme }) => theme.form};
  &:hover {
    background-color: ${colors.primary_light};
  }
`;

const SearchBox = styled.input`
  border-bottom: 1px solid #ddd;
  /* margin-bottom: 10px; */

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;
export {
  DropDownMenuWrapper,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownListContainer,
  ListItem,
  SearchBox,
};
