import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { FooterContainer } from "./footer.style";
import { ThemeContext } from "_context/themeContext";
import {
  AskRecruit,
  Button,
  ContactListWrapper,
  Copyright,
  Menu,
  SocialLinks,
} from "_components/atoms";
import lightLogo from "_assets/img/logo-white.png";
import darkLogo from "_assets/img/logo-black.png";
import lightRec from "_assets/img/livroreclamacoes-light.png";
import darkRec from "_assets/img/livroreclamacoes-dark.png";
import ContactList from "_components/atoms/contact-list/contact-list";

const Footer = () => {
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
  return (
    <FooterContainer>
      <div className="left-container">
        <Link className="logo" to="/teste">
          <img
            src={isDarkTheme ? lightLogo : darkLogo}
            alt={isDarkTheme ? "Dark Mode" : "Light Mode"}
          />

          {/* <img
            src={require("_assets/img/logo-black.png")}
            alt="Médicos na Manga Logo"
          /> */}
        </Link>
        <ContactList />
        <div className="social-container">
          <SocialLinks />
        </div>
      </div>
      <div className="middle-container">
        <div className="footer-nav">
          <Menu className="menu" />
          {/* <AskRecruit /> */}
          <div className="app-livro">
            {/* <Button className="button">conheça a nossa app</Button> */}
            <Link className="livro-reclamacao" to="">
              <img
                src={require("_assets/img/livroreclamacoes-light.png")}
                alt="Médicos na Manga Logo"
              />
              {/* <img
          src={isDarkTheme ? lightRec : darkRec}
          alt={isDarkTheme ? 'Dark Mode' : 'Light Mode'}
        /> */}
            </Link>
          </div>
          <Copyright />
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
