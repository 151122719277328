import React from 'react'
import { CategoryButton, CategorySelector } from './category-nav.styled'

const categories = [
    { id: 1, label: 'MÉDICOS' },
    { id: 2, label: 'PSICÓLOGOS' },
    { id: 3, label: 'ENFERMEIROS' },
  ];

const CategoryNav = ({ onSelectCategory, selectedCategory }) => {
    
  return (
    <CategorySelector>
    {categories.map(category => (
      <CategoryButton
        key={category.id}
        onClick={() => onSelectCategory(category.id)}
        className={selectedCategory === category.id ? 'active' : ''}
      >
        {category.label}
      </CategoryButton>
    ))}
  </CategorySelector>
  )
}

export default CategoryNav