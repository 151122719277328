import styled from "styled-components";
import { colors } from "_theme/colors";
import banner from "_assets/img/banners/BANNER-Homepage-02.png";

const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .common-banner {
    /* margin-top: -4rem; */
    background-image: url(${banner});
    width: 100%;
    height: 13rem;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;

    h1 {
      font-size: 4rem;
      color: ${colors.white};
      text-transform: uppercase;
      margin: 0 0 0 10%;
    }
  }

  

`;
export { FaqWrapper };
