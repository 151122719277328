import React from 'react'
import { Link } from 'react-router-dom';
import { ContactListWrapper } from './contact-list.style'

import { ReactComponent as Whatsapp } from "_assets/img/coms/whatsapp-icon.svg";
import { ReactComponent as Messenger } from "_assets/img/coms/messenger-icon.svg";
import {ReactComponent as Phone} from "_assets/img/icons/phone.svg";

const ContactList = () => {
  return (
    <ContactListWrapper>
<div className="contact-container">
          <div className="frame">
            <Phone alt="Telefone" className='tel'/>
          </div>
          <div className="contact-link">
            <Link
              className="phone"
              to="tel:911 140 301"
              target="_blank"
            >
              <p>+351 911 140 301</p>
              <Whatsapp />
            </Link>

            <a
              className="email"
              onClick={() =>
                (window.location =
                  "mailto:geral@medicosnamanga.pt?subject=Pedido de Informação&body=")
              }
            >
              <p>geral@medicosnamanga.pt</p>
              <Messenger />
            </a>
          </div>
        </div>

    </ContactListWrapper>
  )
}

export default ContactList