import styled from "styled-components";
import { colors } from "_theme/colors";

const ProfileCardWrapper = styled.div``;

const ProfileTemplate = styled.div`
  border: 2px solid ${colors.dark_border};
  padding: 1.5rem;
  border-radius: 5px;
  width: 300px;
  min-width: fit-content;
  max-width: 300px;
  /* margin: 2rem; */
  text-align: center;
  background: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  height: 100%;

  .staff-img {
    img {
      object-fit: cover;
      width: 136px;
      height: 136px;
      border-radius: 50%;
    }
  }

  .staff-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    p {
      padding: 0.25rem 0;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      width: 100%;

      Button {
        width: 100%;
        /* background-color: ${colors.primary_2}; */
      }
    }
  }
`;

export { ProfileCardWrapper, ProfileTemplate };
