import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const ProfileInfoWrapper = styled.div`
  padding: 2rem;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  ${screens.lg} {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .profile-info {
    /* border: 3px solid red; */
    width: 33%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: start;
    text-align: center;
    max-width: 960px;
    text-align: start;
    border: 2px solid ${colors.light_border};
    border-radius: 5px;

    ${screens.lg} {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }
`;
export { ProfileInfoWrapper };
