export const colors = {
  white: "rgb(255, 255, 255)",
  primary: "rgb(20, 97, 168)",
  primary_light: "rgba(20, 97, 168, 0.15)",
  primary_2: "rgb(15, 127, 229)",
  secondary: "rgb(97, 179, 222)",
  danger: "rgb(229, 77, 77)",
  text: "rgb(55, 65, 81)",
  text_muted: "rgb(142, 142, 142)",
  table_background: "rgb(250, 249, 249)",
  outline: "rgb(135, 191, 242)",
  box_shadow: " 0 1px 6px 0 rgba(32, 33, 36, 0.278)",
  input_shadow: "0 0 0 2px rgba(0, 114, 206, 0.2)",
  light_background: "rgb(255, 255, 255)",
  light_background_emphasis: "rgb(244, 244, 244)",
  light_text: "rgb(28, 28, 28)",
  light_icon: "rgb(48, 48, 48)",
  light_border: "rgb(186, 186, 186)",
  light_secondary: "rgb(142, 142, 142)",
  dark_background: "rgb(13, 13, 13)",
  dark_background_emphasis: "rgb(33, 36, 39)",
  dark_text: "rgb(227, 227, 227)",
  dark_icon: "rgb(216, 216, 216)",
  dark_border: "rgb(186, 186, 186)",

  decorative_gradient:
    "linear-gradient(0deg,rgba(100, 173, 240, 0.5) 0%,rgba(100, 173, 240, 1) 100%);",
  gradient_logo_circle:
    " linear-gradient(0deg,rgba(100, 184, 225, 0.22) 0%,rgba(97, 179, 222, 0.37) 22%,rgba(88, 166, 216, 0.54) 37%,rgba(74, 144, 204, 0.72) 54%,rgba(71, 139, 202, 0.76) 72%,rgba(36, 109, 178, 0.93) 76%,rgba(21, 96, 168, 1) 100%);",
  gradient_logo_text:
    "linear-gradient(0deg,rgba(100, 184, 225, 0.22) 0%,rgba(97, 179, 222, 0.36) 22%,rgba(88, 166, 216, 0.52) 36%,rgba(74, 144, 204, 0.68) 52%,rgba(71, 139, 202, 0.71) 68%,rgba(36, 109, 178, 0.92) 71%,rgba(21, 96, 168, 1) 100%);",
  navbar_gradient:
    "linear-gradient(to bottom, rgba(255, 255, 255, 0.83) 30%, rgba(249, 249, 249, 0.02) 100%);",
  background_gradient:
    "linear-gradient(to bottom, rgba(255, 255, 255) 20%, rgba(233, 233, 233) 100%);",
  background:
    "radial-gradient(74.52% 72.72% at 50% 50%,#FFFFFF 38.65%,#7EA8CF 67.83%,#417FB9 94.96%,#1461A8 100%)",
  dots: "linear-gradient(to bottom, rgba(207, 207, 207, 1) 0%, rgba(207, 207, 207, 0) 100%);",
};

export const darkTheme = {
  background: "rgb(13, 13, 13)",
  background_emphasis: "rgb(33, 36, 39)",
  background_emphasis_header: "rgb(33, 36, 39)",
  background_dashboard:
    "linear-gradient(to bottom, rgba(55, 55, 55) 20%, rgba(0, 0, 0) 100%);",
  box_shadow: "0 4px 8px rgba(255,255,255, 0.2)",
  table_background: "rgba(33,36,39, 1)",
  table_hover: "rgb(88,88,88)",
  text: "rgb(227, 227, 227)",
  icon: "rgb(216, 216, 216)",
  border: "rgb(186, 186, 186)",
  form: "rgb(33,36,39)",
  fill: "rgb(227,227,227)",

  navbar_gradient:
    "linear-gradient(to bottom, rgba(55, 55, 55, 0.83) 30%, rgba(49, 49, 49, 0.02) 100%);",

  filter: "invert(1)",
};

export const lightTheme = {
  background: "rgb(255, 255, 255)",
  background_emphasis: "rgb(244, 244, 244)",
  background_emphasis_header: "rgb(255, 255, 255)",
  background_dashboard:
    "linear-gradient(to bottom, rgb(255, 255, 255) 20%, rgb(233, 233, 233) 100%);",
  box_shadow: "0 4px 8px rgba(33, 36, 39, 0.2)",
  table_background: "rgb(250, 249, 249)",
  table_hover: "rgb(197,197,197)",
  text: "rgb(55, 65, 81)",
  icon: "rgb(48, 48, 48)",
  border: "rgb(186, 186, 186)",
  form: "rgb(255,255,255)",
  fill: "rgb(55,65,81)",
  secondary: "rgb(142, 142, 142)",
  navbar_gradient:
    "linear-gradient(to bottom, rgba(255, 255, 255, 0.83) 30%, rgba(249, 249, 249, 0.02) 100%);",

  filter: "invert(0)",
};
