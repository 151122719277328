import React, { useEffect, useState } from 'react';
import { ProfileWrapper } from './profile.style';
import { ProfileInfo } from '_components/atoms';
import { useParams } from 'react-router-dom';

const Profile = () => {
  const { id } = useParams();
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    // Fetch the profile data from profile.json
    const fetchProfileData = async () => {
      try {
        const response = await fetch('/data/profile.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Find the profile with the matching id
        const profile = data.find(p => p.id.toString() === id);
        setProfileData(profile);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchProfileData();
  }, [id]); // Ensure the effect runs when id changes

  if (!profileData) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  return (
    <ProfileWrapper>
      <ProfileInfo
        key={profileData.id}
        id={profileData.id}
        photo={profileData.photo}
        title={profileData.title}
        speciality={profileData.speciality}
        formation={profileData.formation}
        activity={profileData.activity}
        areas={profileData.areas}

      />
    </ProfileWrapper>
  );
};

export default Profile;
