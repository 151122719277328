import { colors } from "_theme/colors";
import styled from "styled-components";

const LoginWithWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h2 {
    font-size: medium;
    text-align: center;
  }

  .icon {
    height: 2.625rem;
    fill: ${({ theme }) => theme.fill};

    &:hover {
      fill: ${colors.primary};
    }
  }

  .login-link {
    display: flex;
    justify-content: space-evenly;
  }
`;

export { LoginWithWrapper };
