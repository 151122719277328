import React, { useState } from "react";
import { ContactsWrapper } from "./contacts.style";
import contacts from "_assets/img/svg/contactos.svg";
import { Button, ContactListWrapper, SocialLinks } from "_components/atoms";

const Contacts = () => {
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [descricao, setDescricao] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false); // Estado para verificar tentativa de envio

  const handleSendEmail = () => {
    // Verificação se todos os campos estão preenchidos
    if (!nome || !telefone || !email || !descricao) {
      setAttemptedSubmit(true); // Ativa o estado para mostrar erros
      return; // Interrompe a função caso algum campo esteja vazio
    }

    // Montagem da URL mailto
    const subject = encodeURIComponent(`Pedido de Marcação de consulta - ${nome}`);
    const body = encodeURIComponent(`Nome: ${nome}\nTelefone: ${telefone}\nEmail: ${email}\nDescrição: ${descricao}`);
    window.location.href = `mailto:geral@medicosnamanga.pt?subject=${subject}&body=${body}`;
  };

  return (
    <ContactsWrapper>
      <div className="contacts-container">
        <div className="left-container">
          <img src={contacts} alt="contacts-banner" />
        </div>

        <form className="right-container" onSubmit={(e) => e.preventDefault()}>
          <h1>Contactos</h1>
          <div className="contacts-container">
            <ContactListWrapper />
          </div>
          <div className="social-container">
            <p>Pode seguir-nos através...</p>
            <SocialLinks />
          </div>
          <div className="email-container">
            <h2>Faça a sua marcação aqui!</h2>
            <div className="input-container">
              <input
                type="text"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                className={!nome && attemptedSubmit ? 'error' : ''}
              />
              <input
                type="phone"
                placeholder="Telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                className={!telefone && attemptedSubmit ? 'error' : ''}
              />
            </div>
            <div className="input-container">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={!email && attemptedSubmit ? 'error' : ''}
              />
            </div>
            <div className="textarea-container">
              <textarea
                id="textarea"
                cols="30"
                rows="7"
                placeholder="Descreva aqui o tipo de consulta pretendido"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                className={!descricao && attemptedSubmit ? 'error' : ''}
              />
            </div>
          </div>
          <div className="button-container">
            <Button onClick={handleSendEmail}>Enviar</Button>
          </div>
        </form>
      </div>
    </ContactsWrapper>
  );
};

export default Contacts;
