import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const RegistryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 5rem;

  ${screens.lg} {
    padding: 1rem 0;
  }

  form {
    width: 50%;
    ${screens.lg} {
      width: 100%;
    }
  }

  .register-row {
    display: flex;
    gap: 2rem;

    div {
      flex: 1;
    }
  }

  .register-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 4rem;
    ${screens.lg} {
      gap: 1rem;
    }

    .title {
      text-align: center;

      h1 {
        color: ${colors.primary};
        text-transform: uppercase;
        font-size: x-large;
      }
    }

    .buttons {
      display: flex;
      gap: 2rem;
      justify-content: center;

      .button-active {
        background: ${colors.primary_2};
        color: ${colors.text};
      }
    }

    .register {
      display: flex;
      flex-direction: column;
      gap: 3rem;

      ${screens.lg} {
        gap: 1rem;
      }

      .register-row {
        display: flex;
       align-items: flex-end;
        gap: 2rem;
        ${screens.lg} {
          gap: 1rem;
        }
        div {
          flex: 1;
        }
      }
    }

    .password-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .password-input,
      .password-input-confirm {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 2rem;
      }
    }

    .form-control {
      &#Search {
        border-radius: none;
        outline: none;
        box-shadow: none;
        position: sticky;
        top: 0;
      }
    }
    .icon-password,
    .icon-password-confirm {
      position: absolute;
      padding-right: 1rem;
      cursor: pointer;

      path {
        fill: ${colors.primary};
      }
    }

    .button-error-success {
      text-align: center;
      button {
      }
    }
  }
  input:focus {
  }

  .terms-agree {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      color: ${colors.primary_2};
      text-decoration: underline;
    }
  }
`;

export { RegistryWrapper };
