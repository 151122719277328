import { screens } from "_theme/screens";
import styled from "styled-components";

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2rem;
  gap: .5rem;
  

  ${screens.lg} {
    /* padding-left: 1rem; */
  }

  ${screens.md} {
    /* padding-left: 0.5rem; */
  }

  label {
    transition: transform 0.1s;
  }
  input {
    display: none;
  }

  img {
    filter: invert(1);
    width: 2rem;
  }
`;

export { SwitchWrapper };
