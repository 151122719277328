import React from "react";
import { ButtonContainer } from "./button.styles";

const Button = (props) => {
  const { children, onClick, className, preset = "primary" } = props;

  return (
    <ButtonContainer className={className} onClick={onClick} preset={preset}>
      {children}
    </ButtonContainer>
  );
};

export default Button;
