import styled from "styled-components";
import background from "_assets/img/carousel/banner1.png";
import { colors } from "_theme/colors";

const RecruitWrapper = styled.div`
  /* margin-top: -4rem; */

  .recruit-container {
    display: flex;
    height: 100vh;

    .left-container,
    .right-container {
      flex: 1;
      padding: 6rem 9.25rem 5rem 6rem;
    }

    .left-container {
      background-image: url(${background});
      background-size: cover;
    }

    .right-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
   

      h1 {
        color: ${colors.primary_2};
        text-transform: uppercase;
        font-style: italic;
      }

      .input-container {
        width: 100%;
      }

      .email-container,
      .contact-container {
        display: flex;
        gap: 1rem;
        margin: 1rem 0;
        input {
          padding: 0.5rem;
          font-size: medium;
          border: none;
          border-bottom: 2px solid ${colors.light_border};
          width: 100%;
          transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

          &:focus {
            border-radius: 2px;
            outline: 2px solid #87bff2;
            box-shadow: 0 0 0 0.25rem #0f7fe540;
          }
        }
      }

      .textarea-container {
        display: flex;
        max-width: 100%;
      }
      textarea {
        width: 100%;
        resize: vertical;
        min-height: 5rem;
        max-height: 15rem;
        overflow: auto;
        padding: 0.5rem 1rem;
        font-size: medium;
        border: none;
        border-bottom: 2px solid ${colors.light_border};
        border-left: 2px solid ${colors.light_border};
        transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
          border-radius: 2px;
          outline: 2px solid #87bff2;
          box-shadow: 0 0 0 0.25rem #0f7fe540;
        }
      }
    }

    .file-button{
        text-align: start;
    }

    .terms {
      display: flex;
      justify-content: end;
      .terms-link {
        color: ${colors.primary};
        text-decoration: underline;
      }
    }

    .send-button{
        text-align: end;
    }
  }
`;

export { RecruitWrapper };
