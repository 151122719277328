import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  ${screens.lg} {
     padding: 1rem 0;
  }

  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 20rem;

    .login {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      ${screens.lg} {
     br{
      display: none;
     }
  }

      h1 {
        color: ${colors.primary};
        text-transform: uppercase;
        font-size: x-large;
      }

      .password-container {
        text-align: end;
        .password-input {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          gap: 2rem;
        }

        a {
          color: ${colors.primary};
          text-decoration: underline;

          font-size: 14px;
        }
      }



      .icon-password {
        position: absolute;
        padding-right: 1rem;
        cursor: pointer;

        path {
          fill: ${colors.primary};
        }
      }

      .button-error-success {
        button {
          width: 100%;
        }
      }

      .registry {
        text-align: center;
        width: 100%;
      }
    }
  }

  .image-container {
    ${screens.lg} {
      display: none;
    }
  }

  Button{
    width: 100%;
  }
`;

export { LoginWrapper };
