import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const FooterContainer = styled.div`
  height: 14rem;

  background: ${({ theme }) => theme.background_emphasis};

  padding: 1.25rem 2rem;
  display: flex;
  justify-content: space-between;

  ${screens.lg} {
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }

  .left-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo {
      width: fit-content;
    }
  }

  .social-container {
    ${screens.lg} {
      margin: 0 auto;
    }
  }

  .middle-container {
    margin: 0 0 0 4rem;
    width: 100%;

    ${screens.lg} {
      margin: 0;
    }

    .footer-nav {
      display: flex;
      flex-direction: column;

      gap: 1.5rem;
      padding-top: 0.5rem;
      padding-bottom: 1rem;
    }
    .app-livro {
      display: flex;
      justify-content: end;
      /* justify-content: space-between; */
      align-items: center;
      width: 100%;

      .livro-reclamacao {
        text-align: right;
        img {
          filter: ${({ theme }) => theme.filter};
        }
      }
    }
    .button {
      text-transform: uppercase;
    }
  }
`;
export { FooterContainer };
