import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { RegistryWrapper } from "./register.style";
import { Button } from "_components/atoms";
import DropDownMenu from "_components/molecules/dropdown-menu/profession/dropdown-menu";
import LocalDropDownMenu from "_components/molecules/dropdown-menu/local/dropdown-menu";

function Register() {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const telRef = useRef(null);
  const [activeForm, setActiveForm] = useState("medico");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [rgpdAccepted, setRgpdAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isFormValid = termsAccepted && rgpdAccepted;

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
    setErrorMessage("");
  };

  const handleRgpdChange = (e) => {
    setRgpdAccepted(e.target.checked);
    setErrorMessage("");
  };



  const handleMedicoClick = () => {
    setActiveForm("medico");
  };

  const handleEntidadeClick = () => {
    setActiveForm("entidade");
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (!isFormValid) {
      setErrorMessage(
        "Você deve aceitar os Termos e Condições e o RGPD para se registrar."
      );
      return;
    }
  };

  return (
    <RegistryWrapper>
      <form action="post" onSubmit={handleRegister}>
        <div className="register-container">
          <div className="title">
            <h1>Registe-se</h1>
            <h2>Escolher uma das opções abaixo:</h2>
          </div>
          <div className="buttons">
            <Button
              className={activeForm === "medico" ? "button-active" : "button"}
              onClick={handleMedicoClick}
            >
              Médico Singular
            </Button>
            <Button
              className={activeForm === "entidade" ? "button-active" : "button"}
              onClick={handleEntidadeClick}
            >
              Entidade Coletiva
            </Button>
          </div>
          <div className="register">
            {activeForm === "medico" && (
              <>
                <div className="register-row">
                  <DropDownMenu />
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Nº Cédula Profissional"
                      // ref={nameRef}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="register-row">
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Nome"
                      ref={nameRef}
                      autoComplete="off"
                    />
                  </div>
                  <LocalDropDownMenu />
                </div>
                <div className="register-row">
                  <div>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      ref={emailRef}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="tel"
                      // pattern="[0-9]{3}[0-9]{3}[0-9]{3}" required
                      placeholder="Telemóvel"
                      ref={telRef}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </>
            )}
            {activeForm === "entidade" && (
              <>
                <div className="register-row">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nome da entidade"
                    // ref={nameRef}
                    autoComplete="off"
                  />
                </div>
                <div className="register-row">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nome da pessoa responsável"
                    // ref={nameRef}
                    autoComplete="off"
                  />
                </div>
                <div className="register-row">
                  <LocalDropDownMenu />
                  <div></div>
                </div>
                <div className="register-row">
                  <div>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      // ref={emailRef}
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      type="tel"
                      // pattern="[0-9]{3}[0-9]{3}[0-9]{3}" required
                      placeholder="Telemóvel"
                      // ref={telRef}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="terms-agree">
            <div>
              <input
                type="checkbox"
                name="terms-agree"
                checked={termsAccepted}
                onChange={handleTermsChange}
              />
              <span>
                Aceito os <Link to="/terms">Termos e Condições.</Link>
              </span>
            </div>
            <div>
              <input
                type="checkbox"
                name="RGPD"
                checked={rgpdAccepted}
                onChange={handleRgpdChange}
              />
              <span>
                Aceito o <Link to="/terms">RGPD.</Link>
              </span>
            </div>
          </div>
          <div className="button-error-success">
            <Button type="submit" disabled={!isFormValid}>
              Registar
            </Button>
           
          </div>
        </div>
      </form>
       {!isFormValid && <div style={{ color: "red" }}>{errorMessage}</div>}

    </RegistryWrapper>
  );
}

export default Register;
