import React from "react";
import { ProfileInfoWrapper } from "./profile-info.style";

const ProfileInfo = (props) => {
  const photo = props.photo;
  const imagePath = "/assets/img/profile/" + photo;
  return (
    <ProfileInfoWrapper>
      <div className="profile-info">
        <img src={imagePath} alt={`Perfil de ${props.title}`} />
      </div>
      <div className="profile-info">
        <h2>{props.title}</h2>
        <p>Especialidade</p>
        <p>{props.speciality}</p>
      </div>
      <div className="profile-info">
        <h3>Formação Académica</h3>
        <p>{props.formation}</p>
        <h3>Actividade Profissional</h3>
        <p>{props.activity}</p>
        <h3>Áreas de Actuação</h3>
        <p>{props.areas}</p>
      </div>
    </ProfileInfoWrapper>
  );
};

export default ProfileInfo;
