import React from "react";
import { HomeMenuWrapper, MenuItem } from "./home-menu.style";

import image1 from "_assets/img/menu/menu1.jpg";
import image2 from "_assets/img/menu/menu2.jpg";
import image3 from "_assets/img/menu/menu3.jpg";
import image4 from "_assets/img/menu/menu4.jpg";
import { Link, NavLink } from "react-router-dom";

const HomeMenu = () => {
  // Exemplo de URL de imagem - você pode substituir por qualquer URL de imagem de sua escolha

  return (
    <HomeMenuWrapper>
      <Link to="/services">
        <MenuItem>
          <img src={image1} alt="Menu Item 1" />
          <div className="tag">
            <h2>Serviços</h2>
          </div>
        </MenuItem>
      </Link>
      <Link to="/contacts">
        <MenuItem>
          <img src={image2} alt="Menu Item 2" />
          <div className="tag">
            <h2>Contactos</h2>
          </div>
        </MenuItem>
      </Link>
      <Link to="/about">
        <MenuItem>
          <img src={image3} alt="Menu Item 3" />
          <div className="tag">
            <h2>Sobre Nós</h2>
          </div>
        </MenuItem>
      </Link>
      <Link to="/calculator">
        <MenuItem>
          <img src={image4} alt="Menu Item 4" />
          <div className="tag">
            <h2>Onde estamos?</h2>
          </div>
        </MenuItem>
      </Link>
    </HomeMenuWrapper>
  );
};

export default HomeMenu;
