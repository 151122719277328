import { colors } from "./colors";
import { screens } from "./screens";

export const formControlStyles = (theme) => `
  .form-control {
    padding: 0.5rem;
    font-size: medium;
    border: none;
    border-bottom: 2px solid ${colors.light_border};
    width: 100%;
    padding-left: 1rem;
    transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: ${theme.form};
    border-radius: 2px;
    
    &:hover {
      // outline: 1px solid ${colors.outline}; 
      // box-shadow: ${colors.input_shadow};
      outline: none; 
      box-shadow: none;
    border-bottom: 2px solid ${colors.primary_2};

    }
    &:focus {
      // outline: 1px solid ${colors.outline};
      // box-shadow: ${colors.input_shadow};
    border-bottom: 3px solid ${colors.primary_2};

      outline: none; 
      box-shadow: none;
    }
  }
  .form-control.idade{
    width: 5rem;
  }
`;

export const formContainerStyles = (theme) => `
  form{
  background: ${({ theme }) => theme.background};
  height: 100%;
  padding: .5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  ${screens.lg} {
    gap:1rem;
  }

  .form-row {
    display: flex;
    gap: 1rem;

    ${screens.lg} {
      flex-direction: column;
    }

    &.name div:nth-of-type(1) {
      display: flex;
      gap: 1rem;
      flex-basis: 500px;
    }
  }

  .form-button {
    align-self: end;
  }
  
  Button {
    margin: 0 auto;
    width: fit-content;
  }
  }
`;

export const SearchbarContainerStyles = (theme) => `
.searchbar-container {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1rem;
}
`;

