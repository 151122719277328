import React, { useState, useEffect } from 'react';
import { StaffWrapper } from './staff.style';
import { ProfileCard } from '_components/atoms';
import { CategoryNav } from '_components/molecules';

const Staff = () => {
    const [selectedCategory, setSelectedCategory] = useState(1);
    const [staffMembers, setStaffMembers] = useState([]);

    useEffect(() => {
        const loadStaffData = async () => {
            try {
                const response = await fetch('/data/profile.json');
                if (!response.ok) {
                    throw new Error('Falha ao carregar dados');
                }
                const data = await response.json();
                setStaffMembers(data);
            } catch (error) {
                console.error('Erro ao carregar dados:', error);
            }
        };

        loadStaffData();
    }, []);

    const filteredStaffMembers = staffMembers.filter(
        member => member.category === selectedCategory
    );

    return (
        <StaffWrapper>
            <div className="staff-banner">
                <h1>Corpo Clínico</h1>
            </div>
            <div className="staff-container">
                <div className="nav-tabs">
                    <CategoryNav
                        onSelectCategory={setSelectedCategory}
                        selectedCategory={selectedCategory}
                    />
                </div>
                <div className="tab-content">
                    {filteredStaffMembers.map(member => (
                        <ProfileCard
                            key={member.id}
                            id={member.id}
                            photo={member.photo}
                            title={member.title}
                            speciality={member.speciality}
                            location={member.location}
                            rating={member.rating}
                        />
                    ))}
                </div>
            </div>
        </StaffWrapper>
    );
};

export default Staff;
