import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

const NavWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 5.125rem;
  padding: 0.5rem 2rem;
  background: ${({ theme }) => theme.navbar_gradient};
  backdrop-filter: blur(5px);
  text-transform: uppercase;
  white-space: nowrap;


  ${screens.md} {
    height: 3rem;
    padding: 0.5rem 1rem;
  }
  .menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-transform: none;

    ${screens.lg} {
justify-content: center;
  }

    .menu-upper {
      display: flex;
      justify-content: end;
      font-size: 13px;

      ${screens.lg} {
    display: none;
  }

      span {
        margin: 0 1rem;
        color: ${colors.text_muted};
      }
      p {
        color: ${colors.text_muted};
      }

      .LinkWrapper {
        gap: 0.5rem;
        margin: 0 2rem;
        .icon {
          height: 1rem;
          path {
            /* fill: ${({ theme }) => theme.icon}; */
            fill: ${colors.text_muted};
          }

          &:hover {
            path {
              fill: ${colors.primary_2};
            }
          }
        }
      }
    }
    .menu-lower {
      display: flex;
      align-items: center;
      font-size: 14px;
      ${screens.lg} {
    display: none;
  }
    }

  }

  .nav-container {
    display: flex;
    align-items: center;
    height: 4.75rem;
    padding: 0.5rem 2rem;
    background: ${colors.navbar_gradient};
    box-shadow: 0 -6px 12px ${colors.shadow};
    text-transform: uppercase;
    font-size: 1rem;
  }

  .login {
    margin: 0 2rem;
    :hover {
      color: ${colors.primary};
    }
    Button {
      border: none;
      padding: 0;
      font-size: 14px;
      box-shadow: none;
      height: 2rem;
    }

    ${screens.lg} {
      display: none;
    }
  }

  .make-appointment {
    Button {
      height: 2rem;
    }
  }

  .burger {
    ${screens.lg} {
      display: block;
    }
  }

  .switch {
    margin-left: 1rem;
    p {
      display: none;
    }
  }
`;

export { NavWrapper };
