import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  DicasMenuContainer,
  DicasMenuWrapper,
  MenuItem,
} from "./dicas-menu.style";

import image1 from "_assets/img/dicas/dicas1.jpg";
import image2 from "_assets/img/dicas/dicas2.jpg";
import image3 from "_assets/img/dicas/dicas3.jpg";
import image4 from "_assets/img/dicas/dicas4.jpg";

const DicasMenu = () => {
  // Exemplo de URL de imagem - você pode substituir por qualquer URL de imagem de sua escolha

  return (
    <DicasMenuWrapper>
      <div className="title">
        <h3>Dicas</h3>
      </div>

      <DicasMenuContainer>
        <MenuItem>
          <Link
            to="https://instagram.com/medicosnamanga?igshid=OGQ5ZDc2ODk2ZA=="
            target="_blank"
          >
            {" "}
            <img src={image1} loading="lazy" alt="Menu Item 1" />
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to="https://instagram.com/medicosnamanga?igshid=OGQ5ZDc2ODk2ZA=="
            target="_blank"
          >
            <img src={image2} loading="lazy" alt="Menu Item 2" />
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to="https://instagram.com/medicosnamanga?igshid=OGQ5ZDc2ODk2ZA=="
            target="_blank"
          >
            <img src={image3} loading="lazy" alt="Menu Item 3" />
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to="https://instagram.com/medicosnamanga?igshid=OGQ5ZDc2ODk2ZA=="
            target="_blank"
          >
            <img src={image4} loading="lazy" alt="Menu Item 4" />
          </Link>
        </MenuItem>
      </DicasMenuContainer>
    </DicasMenuWrapper>
  );
};

export default DicasMenu;
