import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const Dropdown = styled.div`
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`;

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${colors.primary_2};
  cursor: pointer;
  border-radius: 5rem;
  justify-content: center;

  .profile-container {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5rem;
  }
  /* ${screens.lg} {
      display: none;
  } */

  &::after {
    /* Styling for the arrow icon, similar to LanguageSelector */
  }
`;

const DropdownMenu = styled.div`
  transform: ${({ isOpen }) =>
    isOpen ? "translatey(130px)" : "translatey(75px)"};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  background: ${({ theme }) => theme.background_emphasis};
  border: 2px solid ${colors.primary_2};
  border-radius: 5px;
  width: fit-content;
  margin-left: -5rem;
  transition: transform 0.2s ease, opacity 0.2s ease, visibility 0.2s ease;
  position: absolute;

  ${screens.lg} {
    transform: ${({ isOpen }) =>
    isOpen ? "translateY(150px)" : "translateY(100px)"};
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    margin-left: 0;
    right: 1rem;
  }

  .menu-item {
    padding: 0.5rem 0 0;


    &:hover {
      cursor: pointer;
      background-color: ${colors.primary_light};
      border-bottom: 2px solid ${colors.primary_2};
      a:nth-of-type(1){
        border: none;
      }
    }
  }
`;

export { Dropdown, DropdownMenu, DropdownButton };
