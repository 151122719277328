import styled from "styled-components";
import { colors } from "_theme/colors";

const ButtonContainer = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 2.625rem;
  transition: transform 0.2s;
  font-weight: 700;
  font-size: medium;
  text-transform: uppercase;
  gap: .5rem;

  svg{
width: 2rem;
    path{
      fill: ${({ theme }) => theme.fill};
    }
  }

  &:hover {
    path{
      fill: ${colors.danger};
    }
    
  }
`;
export { ButtonContainer };
