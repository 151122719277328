import { screens } from "_theme/screens";
import styled from "styled-components";

const CopyrightWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    font-size: x-small;
    flex-wrap: wrap;

    ${screens.lg} {
      flex-direction: column-reverse;
     
  }
 span{
    ${screens.lg} {
        text-align: end;
  }
       
      }
    .navigation{
        gap:1rem;
        display: flex;
    }
`
export {CopyrightWrapper}