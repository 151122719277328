import React from "react";
import { MapsWrapper } from "./map.style";

const Map = () => {
  const mapSrc =
    "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3153.691255972766!2d-8.097806684887!3d37.065015279759326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1595275623456!5m2!1sen!2sus";

  return (
    <MapsWrapper>
      <iframe
        src={mapSrc}
        //   width="1440"
        //   height="550"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </MapsWrapper>
  );
};

export default Map;
