import React from "react";
import { Link } from "react-router-dom";

import StyledMenu from "./burger-menu.styles";
import Button from "../buttons/button/button";
import LanguageToggle from "../language/language-toogle/language-toogle";
import { useTranslation } from "react-i18next";

const BurgerMenu = (props) => {
  const navigation = [
    { name: "Nav.about", href: "/about" },
  { name: "Nav.services", href: "/services" },
  // { name: "Nav.staff", href: "/staff" },
  { name: "Nav.contacts", href: "/contacts" },
  ];

  const { open, setOpen } = props;
  const { t } = useTranslation();
  const handleCloseMenu = () => {
    setOpen(false);
  };

  return (
    <StyledMenu open={open}>
      {navigation.map((item) => (
        <Link
          className="linkstyle"
          onClick={handleCloseMenu}
          key={item.name}
          to={item.href}
        >
        {t(item.name)}
        </Link>
      ))}
      {/* <Link to="/" onClick={handleCloseMenu}>Under Construction</Link> */}
      {/* <Link to="/sobre nós" onClick={handleCloseMenu}>Sobre nós</Link>
      <Link to="/servicos" onClick={handleCloseMenu}>servicos</Link>
      <Link to="/corpo-clinico" onClick={handleCloseMenu}>corpo clínico</Link>
      <Link to="/contactos" onClick={handleCloseMenu}>contactos</Link> */}
      <div className="menu-button">
        <Button>
          <Link to="/login" onClick={handleCloseMenu}>
          {t("Nav.login")}
          </Link>
        </Button>
      </div>
      {/* <LanguageToggle handleCloseMenu={handleCloseMenu} /> */}
    </StyledMenu>
  );
};

export default BurgerMenu;
