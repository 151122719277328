import styled from 'styled-components';
import { colors } from '_theme/colors';

const SearchContainer = styled.div`

.search-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

  

      .icon-search {
        position: absolute;
        cursor: pointer;
        width: 1.5rem;
        margin-right:1rem;
        
        path {
          fill: ${colors.primary};
        }
      }
`;
export { SearchContainer };
