import { Outlet } from "react-router-dom";

import Header from "../../header/header";
import { UserLayoutWrapper } from "./user-layout.style";
import Footer from "_components/organisms/footer/footer";

// import Aside from '../aside/aside';
// import Footer from '../footer/footer';
// import { Links } from '_components/molecules';

const UserLayout = () => {
  return (
    <UserLayoutWrapper>
      <Header />
      {/* <Aside /> */}

        <Outlet />

      <Footer />
    </UserLayoutWrapper>
  );
};

export default UserLayout;
