import styled from "styled-components";
import { colors } from "_theme/colors";

const AddButtonContainer = styled.button`
  background-color: ${(props) =>
    props.preset === "primary" ? "transparent" : "#1461A8"};
  path {
    fill: ${(props) => (props.preset === "primary" ? "#1461A8" : "#111")};
  }

  background-color: transparent;
    border-radius: 0.25rem;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s;
    padding: 0;
    height: fit-content;
    font-weight: 700;

  &:hover {
    /* transform: scale(1.02); */
    box-shadow: 0 0 4px ${colors.primary};
  }
`;
export { AddButtonContainer };
