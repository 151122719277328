import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

import banner from "_assets/img/banners/BANNER-Homepage-02.png";

export const CalculadoraWrapper = styled.div`
  display: flex;
  /* margin-top: -4rem; */
  height: 90vh;
  ${screens.lg} {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .content-container {
    width: 100%;

    flex-basis: 1;
    /* border: 1px solid red; */

    ${screens.lg} {
        height: 50%;
    }

    &.data {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ${screens.lg} {
        h2{
            font-size: 16px;
        }
        padding: 1rem ;
    }

      h2 {
        color: ${colors.primary_2};
        text-transform: uppercase;
      }

      input {
        width: 30%;
        margin: 2rem 0 2rem;
        font-size: 24px;
        padding: 0.5rem;
        text-align: left;
        font-weight: bold;

        ${screens.lg} {
            width: 40%;
margin: 1rem 0;
        /* padding: 1rem ; */
    }
      }

      .post-code {

        p{
            display: flex;
            width: 100%;
        }
        select {
          width: 100%;
          text-align: left;
          padding: 0;
        }
      }

      .address-info {
        /* border: 1px solid red; */
        /* width: 60%; */
        margin-bottom: 2rem;
        p {
          margin: 0.5rem 0;
        }
        ${screens.lg} {
margin-bottom: 1rem;
    }
      }
      .results {
        /* border: 1px solid red; */
        text-align: right;
        margin: 0 0 2rem;
        .professionals-available {
          color: ${colors.secondary};
        }
        .consultation-cost {
          font-weight: bold;
          color: ${colors.primary_2};

          span {
            font-size: 24px;
            font-weight: bold;
            color: ${colors.primary_2};
            ${screens.lg} {
font-size: 18px;
    }
          }
        }
        p {
          margin: 1rem 0;
        }
      }
    }
  }

  Button {
    width: 100%;
  }
`;
