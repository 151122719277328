import styled from "styled-components";
import { colors } from "_theme/colors";

const PrintButtonContainer = styled.button`
  background-color: ${(props) =>
    props.preset === "primary" ? "transparent" : "#1461A8"};
  path {
    fill: ${(props) => (props.preset === "primary" ? "#1461A8" : "#111")};
  }

  border-radius: 0.25rem;
  border: ${colors.primary} 2px solid;

  display: flex;
  align-items: center;

  /* text-align: center; */

  cursor: pointer;
  /* min-width: 11rem; */
  height: 2.625rem;
  width: 2.625rem;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 0 8px ${colors.primary};
  }
`;
export { PrintButtonContainer };
