import styled from "styled-components";
import rgpd from "_assets/img/banners/BANNER-Homepage-02.png";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";


const RgpdWrapper = styled.div`
.rgpd-banner {
    background-image: url(${rgpd});
    width: 100%;
    height: 9rem;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
  }

  .rgpd-container {
    list-style-position: inside;
    text-align: justify;
    margin: 5rem auto 3rem;  
    max-width: 750px;
    ${screens.md} {
      margin: 3rem 1rem;
    }
}

    .rgpd {
    list-style-position: inside;
    line-height: 1.5;
  
  }

  h1 {
    color: ${colors.text_muted};
    font-size: 2.5rem;
    text-align: start;
  }

  h2 {
    color: ${colors.primary_2};
    margin-top: 1rem;
  }

  h1,
  h2 {
    text-transform: uppercase;
    font-weight: 700;
  }

  a{
    text-decoration: underline;
  }

  p{
    margin: 1rem 0;
  }

  ul > li{
    list-style: disc;
    list-style-position: inside;
    margin-left: 1rem;
  }



`

export {RgpdWrapper}