export const screens = {
  sm: "@media (max-width: 750px)",
  //   => @media (max-width: 640px) { ... },

  md: "@media (max-width: 768px)",
  //   => @media (max-width: 768px) { ... }

  lg: "@media (max-width: 1024px)",
  //   => @media (max-width: 1024px) { ... }

  xl: "@media (max-width: 1280px)",
  //   => @media (max-width: 1280px) { ... }

  xxl: "@media (max-width: 1536px)",
  //   => @media (max-width: 1536px) { ... }
};
