import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CalculadoraWrapper } from "./calculadora.style";

import { Button } from "_components/atoms";
import { Map } from "_components/molecules";

const Calculadora = () => {
  const location = useLocation();
  const initialState = location.state?.postalCode || "";
  const [currentPostalCode, setCurrentPostalCode] = useState(initialState);
  const [locationDetails, setLocationDetails] = useState(null);
  const [addressOptions, setAddressOptions] = useState([]);

  const formatPostalCode = (value) => {
    let numbers = value.replace(/\D/g, "");
    numbers = numbers.slice(0, 7);
    if (numbers.length > 4) {
      return `${numbers.slice(0, 4)}-${numbers.slice(4)}`;
    }
    return numbers;
  };

  const handlePostalCodeChange = (event) => {
    const formattedPostalCode = formatPostalCode(event.target.value);
    setCurrentPostalCode(formattedPostalCode);
  };

  const fetchPostalCodeData = async (postalCode) => {
    const [cp4, cp3] = postalCode.split("-");
    if (!cp4 || !cp3) {
      console.error("Invalid postal code:", postalCode);
      return [];
    }

    try {
      const url = `https://www.cttcodigopostal.pt/api/v1/402e32d068c94205b549414d9e523741/${cp4}-${cp3}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const uniqueAddresses = Array.from(
        new Set(data.map((item) => item.morada))
      ).map((morada) => {
        return data.find((item) => item.morada === morada);
      });
      return uniqueAddresses;
    } catch (error) {
      console.error("Error fetching postal code data:", error);
      return []; // Return empty array in case of an error
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (!currentPostalCode) return;

      console.log(`Fetching data for postal code: ${currentPostalCode}`); // Debug log
      const addresses = await fetchPostalCodeData(currentPostalCode);
      setAddressOptions(addresses); // Save the full list of addresses
      if (addresses.length > 0) {
        setLocationDetails(addresses[0]); // Automatically select the first address initially
      } else {
        setLocationDetails(null);
        console.log("No data received for this postal code.");
      }
      if (addresses.length > 0) {
        setLocationDetails({
          distrito: addresses[0].distrito,
          concelho: addresses[0].concelho,
          codigoPostal: addresses[0]["codigo-postal"], // Aqui, ajuste conforme a chave correta
          freguesia: addresses[0].freguesia,
          localidade: addresses[0].localidade,
          infoLocal: addresses[0]["info-local"],
          latitude: addresses[0].latitude, // Extract latitude
          longitude: addresses[0].longitude, // Extract longitude
        });
      } else {
        setLocationDetails(null);
        console.log("No data received for this postal code.");
      }
    };

    loadData();
  }, [currentPostalCode]);

  const handleAddressSelection = (event) => {
    const selectedIndex = event.target.value;
    setLocationDetails(addressOptions[selectedIndex]);
  };

  return (
    <CalculadoraWrapper>
      <div className="content-container">
        <Map />
      </div>
      <div className="content-container data">
        <div className="data">
          <h2>Calcule o custo da sua consulta</h2>
          <div className="post-code">
            <input
              type="text"
              className="form-control"
              value={currentPostalCode}
              onChange={handlePostalCodeChange}
              placeholder="Código Postal"
            />
            <p>
              Rua:{" "}
              <select
                onChange={handleAddressSelection}
                className="form-control"
              >
                {addressOptions.map((address, index) => (
                  <option key={index} value={index}>
                    {address.morada}, {address.localidade}
                  </option>
                ))}
              </select>
            </p>

            {locationDetails && (
              <div className="address-info">
                <p>
                  Distrito: <span>{locationDetails.distrito}</span>
                </p>

                <p>
                  Concelho: <span>{locationDetails.concelho}</span>
                </p>

                <p>
                  Freguesia: <span>{locationDetails.freguesia}</span>{" "}
                </p>

                <p>
                  Localidade: <span>{locationDetails.localidade}</span>
                </p>

                {/* Morada: {locationDetails.morada}
                <br /> */}
                <p>
                  Código Postal: <span>{currentPostalCode}</span>
                </p>
              </div>
            )}
          </div>
          <div className="results">
            {/* <p className="professionals-available">
              10 Profissionais Disponíveis Na Sua Área
            </p> */}
            <p className="consultation-cost">
              TOTAL<span> 25€</span>
            </p>
          </div>
          <Link to="/contacts">
            <Button className="schedule-button">AGENDAR CONSULTA</Button>
          </Link>
        </div>
      </div>
    </CalculadoraWrapper>
  );
};

export default Calculadora;
