import { BurgerIcon, BurgerMenu } from "_components/atoms";
import React, { useState } from "react";
import { BurgerFull } from "./burger.style";



const Burger = () => {
  const [open, setOpen] = useState(false);

  return (
      <BurgerFull>
        <BurgerIcon open={open} setOpen={setOpen} />
        <BurgerMenu open={open} setOpen={setOpen} />
      </BurgerFull>
  );
};

export default Burger;
