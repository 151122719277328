import { colors } from "_theme/colors";
import styled from "styled-components";

const FaqCardWrapper = styled.div`
  .common-container {
    border: 2px solid ${colors.dark_border};
    padding: 1rem;
    border-radius: 5px;
    max-width: 37.5rem;
    min-width: 18.75rem;
    margin: 2rem;

    h2 {
      margin: 1rem 0 0.5rem;
      color: ${colors.primary_2};
    }

    span:hover {
      color: ${colors.primary_2};
    }
  }
  li {
    margin-bottom: 0.5rem;

    .nav-link {
      transition: all 0.2s;
      scroll-margin-top: 5.5rem;

      &:hover {
        color: ${colors.primary};
      }
    }
  }
`;

export { FaqCardWrapper };
