import styled from "styled-components";
import { colors } from "_theme/colors";

const ButtonContainer = styled.button`
  background-color: ${(props) =>
    props.preset === "primary" ? "transparent" : "#0F7FE5"};
  color: ${(props) => (props.preset === "primary" ? "#0F7FE5" : "#111")};
  border-radius: 0.25rem;
  border: ${colors.primary_2} 2px solid;
  padding: 0 1.5rem;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  

  cursor: pointer;
  /* min-width: 11rem; */
  height: 2.625rem;
  transition: transform 0.2s;
  font-weight: 700;

  &:hover {
    
    box-shadow: 0 0 8px ${colors.primary_2};
  }
`;
export { ButtonContainer };
