import React from "react";

import StyledBurger from "./burger-icon.styles";

const BurgerIcon = (props) => {
  const { open, setOpen } = props;

  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default BurgerIcon;
