import React from "react";

import { Container, HomeContainer, MapContainer } from "./home.style";
import Carousel from "_components/molecules/carousel/carousel";
import About from "_pages/user/about/about";
import { Footer } from "_components/organisms";
import { Calculator, DicasMenu, Map } from "_components/molecules";
import HomeMenu from "_components/molecules/home-menu/home-menu";

const Welcome = () => {
  return (
    <HomeContainer>
      <Carousel />
      {/* <Calculator /> */}
      <Container>
        <HomeMenu />
        <DicasMenu />
        {/* <About /> */}
      </Container>
      <MapContainer>
         <Map />
      </MapContainer>
     
      {/* <Footer/> */}
    </HomeContainer>
  );
};

export default Welcome;
