import React from "react";
import { Link } from "react-router-dom";
import { HealthAgreementWrapper } from "./health- agreements.style";

import adse from "_assets/img/temp/logo-adse.png";
import advancecare from "_assets/img/temp/logo-advancecare.png";
import medicare from "_assets/img/temp/logo-medicare.png";
import medis from "_assets/img/temp/logo-medis.png";
import multicare from "_assets/img/temp/logo-multicare.png";
import sams from "_assets/img/temp/logo-sams.png";

const HealthAgreements = (props) => {
  const agreements = [
    { name: "adse", href: "/about", logo: adse },
    { name: "advancecare", href: "/services", logo: advancecare },
    { name: "multicare", href: "/staff", logo: medicare },
    { name: "medicare", href: "/contacts", logo: medis },
    { name: "sams", href: "/contacts", logo: sams },
    { name: "médis", href: "/contacts", logo: medis },
    { name: "médis", href: "/contacts", logo: multicare },
    { name: "medicare", href: "/contacts", logo: medis },
    { name: "sams", href: "/contacts", logo: sams },
    { name: "médis", href: "/contacts", logo: medis },
    { name: "médis", href: "/contacts", logo: multicare },
  ];

  return (
    <HealthAgreementWrapper>
      <div className="agreement-banner">
        <h1>Acordos e Seguros</h1>
      </div>

      <div className="agreement-container">
        {agreements.map((item) => (
          <Link className="linkstyle" key={item.name} to={item.href}>
            <div className="card-template">
              <img src={item.logo} alt="Logo" />
            </div>
          </Link>
        ))}
      </div>
    </HealthAgreementWrapper>
  );
};

export default HealthAgreements;
