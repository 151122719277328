import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const CalculatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  background: ${({ theme }) => theme.background_emphasis};
  ::placeholder {
    text-transform: uppercase;
  }

  ${screens.md} {
    flex-direction: column;
    height: auto;
  }

  input {
    max-width: 14rem;
    background: inherit;

    
  }

  Button {
  }
`;

const CalculatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 67.5rem;

  ${screens.lg} {
width: 100%;
padding: .5rem;
    }

  h3 {
    text-transform: uppercase;
    font-style: italic;
    ${screens.lg} {
font-size: 12px;
    }
    
  }

  .input-group {
    display: flex;
    gap: 1rem;

    Button{
      height: auto;
      padding: 0 1rem;
    }

    ${screens.lg} {
input{
  width: 8rem;
}
    }
  }
`;

export { CalculatorWrapper, CalculatorContainer };
