import React, { useEffect, useRef, useState } from "react";
import {
  DropDownMenuWrapper,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownListContainer,
  ListItem,
  SearchBox,
} from "./dropdown-menu.style";

const jobs = [
  { name: "Médico", value: "medico" },
  { name: "Enfermeiro", value: "enfermeiro" },
  { name: "Psicólogo", value: "psicologo" },
  { name: "Fisioterapeuta", value: "fisioterapeuta" },
  { name: "Farmacêutico", value: "farmaceutico" },
  { name: "Biomédico", value: "biomedico" },
  { name: "Nutricionista", value: "nutricionista" },
];

const DropDownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const wrapperRef = useRef(null);
  const clearSelection = (event) => {
    event.stopPropagation();
    setSelectedOption(null);
    setSearchTerm("");
  };

  const filteredOptions = jobs.filter((job) =>
    job.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    setSearchTerm("");
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  return (
    <DropDownMenuWrapper>
    <DropdownContainer ref={wrapperRef}>
      {isOpen ? (
        <SearchBox
          placeholder="Escolha a sua profissão"
          className="form-control"
          id="Search"
          value={searchTerm}
          autoFocus
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      ) : (
        <DropdownHeader onClick={toggling} className="form-control">
            <span>{selectedOption ? selectedOption.name : "Escolha a sua profissão"}</span>
  <span className={selectedOption ? "icon-clear" : `icon-arrow ${isOpen ? 'open' : ''}`} />
  {selectedOption && (
    <span onClick={clearSelection}>X</span>
  )}
        </DropdownHeader>
      )}
      {isOpen && (
        <DropdownListContainer>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((job) => (
              <ListItem onClick={onOptionClicked(job)} key={job.value}>
                {job.name}
              </ListItem>
            ))
          ) : (
            <ListItem>Nenhuma especialidade encontrada</ListItem>
          )}
        </DropdownListContainer>
      )}
    </DropdownContainer>
  </DropDownMenuWrapper>
  );
};

export default DropDownMenu;
