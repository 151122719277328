import React, { useEffect, useState } from "react";
import { CarouselWrapper } from "./carousel.style";
import { Button } from "_components/atoms";
import { Link } from "react-router-dom";

import bannerImage1 from "_assets/img/carousel/1.jpg";
import bannerImage2 from "_assets/img/carousel/2.jpg";
import bannerImage3 from "_assets/img/carousel/3.jpg";
import bannerImage4 from "_assets/img/carousel/4.jpg";

const phrases = [
  "Cuidamos de si, onde quer que esteja.",
  "A saúde começa em casa.",
  "Cuide de si, sem sair de casa.",
  "Médicos ao domicílio com experiência.",
  "Cuidado médico à medida das suas necessidades.",
  "Médicos online, saúde sem fronteiras.",
  "A sua saúde, a nossa missão.",
  "Médicos à distância de um clique. ",
];

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState(0);

  const slides = [
    { id: 0, img: bannerImage1 },
    { id: 1, img: bannerImage2 },
    { id: 2, img: bannerImage3 },
    { id: 3, img: bannerImage4 },
  ];

  function showSlide(slideIndex) {
    if (slideIndex >= slides.length) {
      setCurrentSlide(0);
      setCurrentPhrase(0);
    } else if (slideIndex < 0) {
      setCurrentSlide(slides.length - 1);
      setCurrentPhrase(0);
    } else {
      setCurrentSlide(slideIndex);
      setCurrentPhrase(0);
    }
  }

  function nextSlide() {
    showSlide(currentSlide + 1);
  }

  function previousSlide() {
    showSlide(currentSlide - 1);
  }

  useEffect(() => {
    const phraseInterval = setInterval(() => {
      setCurrentPhrase((current) => (current + 1) % phrases.length);
    }, 4000); // Changes the phrase every 2 seconds
    return () => clearInterval(phraseInterval);
  }, []);

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 12000); // Changes slide every 12 seconds
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <CarouselWrapper>
      <div className="carouselContainer">
        {slides.map((slide, index) => (
          <div
            loading="lazy"
            key={slide.id}
            className={`carouselImgs slide${slide.id} ${
              currentSlide === slide.id ? "active" : "hidden"
            }`}
            style={{ backgroundImage: `url(${slide.img})` }}
          >
            <div className="slide-info">
              <div className="slide-text">
              <h1 key={currentPhrase}>{phrases[currentPhrase]}</h1>
                {/* <p>Nunca o seu médico esteve tão próximo</p> */}
              </div>
              <Link to="tel:911 140 301">
                <Button className="button">CHAME UM MÉDICO AGORA!</Button>
              </Link>
            </div>
          </div>
        ))}

        <div className="linkContainer">
          <div className="shortcut">
            <Link to="/contacts">
              <p>Consultas ao domicílio</p>
            </Link>

            <span>|</span>
            <Link to="/contacts">
              {" "}
              <p>Atendimento online personalizado</p>
            </Link>

            <span>|</span>
            <Link to="/contacts">
              <p>Médicos 24h</p>
            </Link>

            <span>|</span>
            <Link to="/contacts">
              <p>Acompanhamento personalizado para sócios</p>
            </Link>
          </div>
          <div className="dotContainer">
            <div className="arrow prev" onClick={previousSlide}></div>
            {slides.map((slide) => (
              <span
                key={slide.id}
                className={`dot ${currentSlide === slide.id ? "active" : ""}`}
                onClick={() => showSlide(slide.id)}
              ></span>
            ))}
            <div className="arrow next" onClick={nextSlide}></div>
          </div>
        </div>
      </div>
    </CarouselWrapper>
  );
};

export default Carousel;

// import React, { useEffect, useState } from "react";
// import { CarouselWrapper } from "./carousel.style";
// import { Button } from "_components/atoms";
// import { Link } from "react-router-dom";

// import bannerImage1 from "_assets/img/carousel/1.jpg";
// import bannerImage2 from "_assets/img/carousel/2.jpg";
// import bannerImage3 from "_assets/img/carousel/3.jpg";
// import bannerImage4 from "_assets/img/carousel/4.jpg";

// const Carousel = () => {
//   const slides = [
//     {
//       id: 0,
//       img: bannerImage1,
//       content: (
//         <div className="slide-info">
//           <div className="slide-text">
//             <h1>O SEU MÉDICO NA PALMA DA SUA MÃO</h1>
//             <p>Nunca o seu médico esteve tão próximo</p>
//           </div>
//           <Link to="tel:911 140 301">
//             <Button className="button">CHAME UM MÉDICO AGORA!</Button>
//           </Link>
//         </div>
//       ),
//     },
//     { id: 1, img: bannerImage2,  content: (
//       <div className="slide-info">
//         <div className="slide-text">
//           <h1>Assistência rápida com os melhores profissionais</h1>
//         </div>
//       </div>
//     ),},
//     { id: 2,  img: bannerImage3, content:(
//       <div className="slide-info">
//         <div className="slide-text">
//           <h1>Porque na saúde, cada momento é precioso,</h1>
//         </div>
//         <Link to="tel:911 140 301">
//           <Button className="button">CHAME UM MÉDICO AGORA!</Button>
//         </Link>
//       </div>
//     ), },
//     { id: 3, img: bannerImage4,  content: (
//       <div className="slide-info">
//         <div className="slide-text">
//           <h1>Assistência rápida com os melhores profissionais</h1>
//         </div>
//       </div>
//     ),},
//     // { id: 3,  img: bannerImage2, content: <h1>Slide 4</h1> },
//     // { id: 4,  img: bannerImage2, content: <h1>Slide 5</h1> },
//   ];

//   const [currentSlide, setCurrentSlide] = useState(0);
//   function showSlide(slideIndex) {
//     if (slideIndex >= slides.length) {
//       setCurrentSlide(0);
//     } else if (slideIndex < 0) {
//       setCurrentSlide(slides.length - 1);
//     } else {
//       setCurrentSlide(slideIndex);
//     }
//   }

//   function nextSlide() {
//     showSlide(currentSlide + 1);
//   }
//   function previousSlide() {
//     showSlide(currentSlide - 1);
//   }

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((currentSlide) => (currentSlide + 1) % slides.length);
//     }, 5000);
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <CarouselWrapper>
//       <div className="carouselContainer">
//         {slides.map((slide, index) => (
//           <div loading="lazy"
//             key={slide.id}
//             className={`carouselImgs slide${slide.id} ${
//               currentSlide === slide.id ? "active" : "hidden"
//             }`}
//             style={{ backgroundImage: `url(${slide.img})` }}
//           >
//             {slide.content}
//           </div>
//         ))}

//         <div className="linkContainer">
//           <div className="shortcut">
//             <Link to="/contacts">
//               <p>Consultas ao domicílio</p>
//             </Link>

//             <span>|</span>
//             <Link to="/contacts">
//               {" "}
//               <p>Atendimento online personalizado</p>
//             </Link>

//             <span>|</span>
//             <Link to="/contacts">
//               <p>Médicos 24h</p>
//             </Link>

//             <span>|</span>
//             <Link to="/contacts">
//               <p>Acompanhamento personalizado para sócios</p>
//             </Link>
//           </div>
//           <div className="dotContainer">
//             <div className="arrow prev" onClick={previousSlide}></div>
//             {slides.map((slide) => (
//               <span
//                 key={slide.id}
//                 className={`dot ${currentSlide === slide.id ? "active" : ""}`}
//                 onClick={() => showSlide(slide.id)}
//               ></span>
//             ))}
//             <div className="arrow next" onClick={nextSlide}></div>
//           </div>
//         </div>
//       </div>
//     </CarouselWrapper>
//   );
// };

// export default Carousel;
