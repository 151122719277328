import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import background from "_assets/img/WEBSITE-ILLUSTRATIONS-03_1.png";
import background2 from "_assets/img/graphics/a.svg";
import filter from "_assets/img/graphics/degrade/Rectangle89.png";
import filter2 from "_assets/img/graphics/degrade/Rectangle92.png";
import background3 from "_assets/img/graphics/Rectangle91.png";
import banner from "_assets/img/banners/BANNER-Homepage-02.png";

const AboutWrapper = styled.div`
  .about-banner {
    background-image: url(${banner});
    width: 100%;
    height: 13rem;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    /* margin-top: -4rem; */

    h1 {
      font-size: 4rem;
      color: ${colors.white};
      text-transform: uppercase;
      margin: 0 0 0 10%;
      font-style: normal;

      ${screens.lg} {
      font-size: 4rem;
    }
    }
  }
  /* background: ${({ theme }) => theme.background};  */
  h1 {
    text-transform: uppercase;
    font-style: italic;
    text-align: start;
    color: ${colors.primary_2};
    margin-bottom: 2rem;
  }

  p {
    text-align: justify;
    padding-left: 1.5rem;
    line-height: 1.5;
    font-style: italic;
    font-weight: lighter;
    margin-bottom: 1rem;
  }

  img {
    /* margin-bottom: 3rem; */
  }

  .top-container {
    display: flex;
    ${screens.lg} {
      flex-direction: column;
    }

    .container-left,
    .container-right {
      flex-basis: 50%;
      /* max-height: 500px; */
      ${screens.lg} {
        flex-basis: auto;
      }
    }

    .container-left {
      background-image: url(${background});
      /* width: 100%; */
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 60%;
      }

      ${screens.lg} {
        height: 250px;
      }
    }

    .container-right {
      text-align: center;
      padding: 4rem 2rem;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .middle-container,
  .bottom-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 2rem 6rem;
    margin: 0px 10rem;

    ${screens.lg} {
      padding: 2rem 1rem;
      margin: auto;
    }

    h1 {
      margin: 3rem 0;
    }
  }

  .middle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;

    .img {
      ${screens.lg} {
        position: absolute;
      }
    }

    .text-container {
      max-width: 625px;
      ${screens.lg} {
        max-width: 100%;
      }
    }
    p {
      color: ${colors.white};
      font-size: 30px;
      font-style: italic;
      font-weight: 500;
      text-align: left;
    }
  }

  /* .middle-content,  */
  .bottom-content {
    max-width: 600px;
  }
  .middle-container {
    background-color: ${colors.primary_2};
  }

  .bottom-container {
    flex-direction: column;
    align-items: center;

    .text-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 2rem;

      ${screens.lg} {
        flex-direction: column;
      }

      .text-content {
        flex-basis: 1;
        text-align: start;
        width: 22rem;
        margin-bottom: 2rem;

        ${screens.lg} {
          margin-bottom: 0;
        }

        h2 {
          padding: 0 0 1.5rem 1.5rem;
          color: ${colors.primary_2};
        }

        p {
          text-align: left;
        }
      }
    }
  }
`;

export const Container1 = styled.div`
  /* display: flex;
    justify-content: center;
    text-align: center; */
  background-image: url(${background2});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;

  .text-container1 {
    background: url(${filter});
    background-repeat: no-repeat;
    background-size: cover;

    padding: 2rem 12rem;

    ${screens.lg} {
      padding: 2rem 2rem;
    }
  }

  .text-container2 {
    background: url(${filter2});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem 16rem;
    text-align: center;

    ${screens.lg} {
      padding: 2rem 2rem;
    }
  }
  h1 {
    margin: 3rem 0;
    ${screens.lg} {
      margin: 1rem 0;
    }
  }

  .text-max1,
  .text-max2 {
    max-width: 70%;

    ${screens.lg} {
      max-width: 100%;

      p {
        text-align: left;
      }
    }
  }

  .text-max2 {
    margin: auto;
  }
`;

export const Container2 = styled.div`
  display: flex;

  .img {
    background-image: url(${background2});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${colors.light_background_emphasis};

    ${screens.lg} {
display: none;
    }
  }

  .content-container {
    flex-basis: 1;
    width: 100%;

    .text-max2 {
      padding: 2rem;
      ul {
        list-style: none;
        padding-left: 0;
      }

      ul li {
        display: flex;
        margin-bottom: 1rem; /* Ajuste conforme necessário */
        .icon {
          width: 24px;
          height: 24px;

          path {
            fill-opacity: 1;
            fill: ${colors.primary_2};
          }
        }
      }
    }
  }
`;

export const Container3 = styled.div`
  background: url(${background3});
  background-size: cover;
  background-position: center;

  .text-container {
    max-width: 55%;
    display: flex;
    align-items: center;
    margin: auto;

    ${screens.lg} {
      max-width: 100%;
    }
    p {
      color: white;
      font-size: 30px;
      padding: 6rem;
      text-align: left;

      ${screens.lg} {
      padding: 1rem 2rem;
      font-size: 16px;
      margin: 0;
    }
    }
    strong {
      color: white;
    }
  }
`;

export { AboutWrapper };
