import { colors } from "_theme/colors";
import styled from "styled-components";

const MenuTabsWrapper = styled.div`
  display: flex;
  text-transform: uppercase;
  font-weight: 700;
  gap: 1rem;


  .linkstyle{
    flex: 1;
    gap: 1rem;

    .tab-container {
    display: flex;
    align-items: center;
    /* flex-direction: row; */
    border: 1px solid ${colors.light_border};
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    /* padding: 2rem; */
    background-color: ${colors.white};
    text-align: center;
    

    .tab-icon{
        position: absolute;

        svg{
            width: 5rem;
            height: 5rem;
        }
        path{
            fill:${colors.primary_light}
        }
    }
    .tab-text{
        text-align: center;
        margin: 2rem auto;
        color: ${colors.primary};
    }
  }
  }

`;

export { MenuTabsWrapper };
