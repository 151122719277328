import React from "react";
import { Link } from "react-router-dom";

import { Wrapper } from "./under-construction.style";

import alpha from "_assets/img/svg/alpha-icon.svg";
import banner from "_assets/img/under-construction/a.svg";
import loading from "_assets/img/under-construction/loading.svg";

const UnderConstruction = () => {
  return (
    <Wrapper>
      <img className="banner" src={banner} alt="banner" />
      <div className="loading">
        <img className="loading_banner" src={loading} alt="loading" />
        <h2>A carregar</h2>
        <p>Em Construção...</p>
      </div>
      <div className="text-container">
        {/* <div className="site-info">
          <h1>Website em Construção</h1>
          <h2>Novidades Brevemente</h2>
        </div> */}

        <div className="contact-info">
          <p>Qualquer dúvida entre em contacto através...</p>
          <div className="contact-link">
            <a
              onClick={() =>
                (window.location =
                  "mailto:geral@medicosnamanga.pt?subject=Pedido de Informação&body=")
              }
              className="hover:scale-125 transition-transform transform-gpu"
            >
              <span>geral@medicosnamanga.pt</span>
            </a>
            <span>e</span>
            <Link to="tel:911 140 301">
              <span>911 140 301</span>
            </Link>
          </div>
        </div>
      </div>
      {/* <Link to="/login">
        <img className="login" src={alpha} alt="Alpha" />
      </Link> */}
    </Wrapper>
  );
};

export default UnderConstruction;
