import React from "react";
import { FaqWrapper } from "./faq.style";
import { FaqCard } from "_components/atoms";

const Faq = () => {
  
  return (
    <FaqWrapper>
      <div className="common-banner">
        <h1>Perguntas Frequentes</h1>
      </div>
      <FaqCard/>
    </FaqWrapper>
  );
};

export default Faq;
