import { colors } from '_theme/colors';
import { screens } from '_theme/screens';
import styled from 'styled-components';

const StyledBurger = styled.button`
  position: relative;
  display: flex;
  align-items: end;
  flex-direction: column;
  justify-content: space-around;
  width: 1rem;
  height: 1.5rem;
  
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 4;
  &:hover{
    /* scale: 1.1; */
  }

  &:focus {
    outline: none;
  }

  ${screens.md} {
    height: 1rem;
  }

  div {
    width: 1.5rem;
    height: 3px;
    background: ${colors.primary_2};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 0.5px;
    border-radius: 3px;

    ${screens.md} {
    height: 2px;
    width: 1rem;
  }

    &:first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
export default StyledBurger;
