import React from 'react'
import { Nav } from '_components/molecules'
import { Navbar } from './header.style'

const Header = () => {
  return (
    <Navbar>
      <Nav/>
    </Navbar>
  )
}

export default Header