import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const LanguageToggleWrapper = styled.div`
  display: inline-flex;
  text-align: center;
  padding: 0.2rem;
  cursor: pointer;
  display: none;

  ${screens.lg} {
    display: block;
  }
`;

const LanguageOption = styled.span`
  padding: 0.2rem 0.5rem;
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  color: ${({ active, theme }) => (active ? colors.primary_2 : colors.text)};
`;

export { LanguageOption, LanguageToggleWrapper };
