import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ThemeContext } from "_context/themeContext";
import {
  Button,
  LanguageSelector,
  Logo,
  Menu,
  SocialLinks,
  Switch,
} from "_components/atoms";
import Burger from "../burger/burger";
import { NavWrapper } from "./nav.style";

const Nav = () => {
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [scrollY, setScrollY] = useState(0);

const handleScroll = () => {
  const position = window.pageYOffset;
  setScrollY(position);
};

useEffect(() => {
  window.addEventListener("scroll", handleScroll, { passive: true });

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);

// Cálculo da opacidade (ajuste conforme necessário)
// Opacidade inicial de 1, diminui conforme o scroll aumenta até um mínimo de 0
const opacity = Math.max(0, 1 - scrollY / 150);

return (
  <NavWrapper
    style={{
      background: `linear-gradient(to bottom, rgba(255, 255, 255, ${
        0.02 * opacity + 0.98 // assume valores entre 0.98 e 1
      }) 30%, rgba(249, 249, 249, ${
        0.83 * opacity + 0.17 // assume valores entre 0.17 e 1
      }) 100%)`,
    }}
  >


      <Logo />

      <div className="menu">
        <div className="menu-upper">
        <Link to="tel:911 140 301">
        <p>911 140 301 (Chamada nacional rede móvel) </p>
            </Link>

          <span>|</span>
          <a
              className="email"
              onClick={() =>
                (window.location =
                  "mailto:geral@medicosnamanga.pt?subject=Pedido de Informação&body=")
              }
            >
              <p>geral@medicosnamanga.pt</p>

            </a>
          <SocialLinks/>
          {/* <LanguageSelector/> */}
        </div>

        <div className="menu-lower">
          <Menu />
          <div className="login">
            <Link to="/login">
              <Button>{t("Nav.login")}</Button>
            </Link>
          </div>
          <div className="make-appointment">
            <Link to="/contacts">
              <Button>Marcar Consulta</Button>
            </Link>
          </div>
          {/* <div className="lang">
        <LanguageSelector />
      </div> */}

         
          <div className="switch">
            {/* <Switch /> */}
          </div>
        </div> <div className="burger">
            <Burger />
          </div>
      </div>
    </NavWrapper>
  );
};

export default Nav;
