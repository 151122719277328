import { screens } from "_theme/screens";
import styled from "styled-components";

export const HomeMenuWrapper = styled.div`
  display: flex;
  justify-content: space-around; // Isso distribui espaço igualmente entre os itens
  align-items: center; // Isso centraliza os itens verticalmente
`;

export const MenuItem = styled.a`
  flex-grow: 1;
  height: 22rem; // Define a altura das divs
  background-color: lightgrey; // Apenas para visualização, você pode mudar conforme necessário
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  ${screens.lg} {
    height: auto;
    h2 {
      font-size: 18px;
    }
  }

  .tag {
    position: absolute;
    display: flex;
    padding: 2rem 1.5rem;
    /* border: 1px solid red; */

    ${screens.lg} {
      width: 100%;
      text-align: center;
      padding: 0;
      h2 {
        font-size: 14px;
      }
    }
  }
  img {
    width: 100%;
  }
`;
