import styled from "styled-components";
import terms from "_assets/img/banners/BANNER-Homepage-02.png";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

const TermsWrapper = styled.div`
  .terms-banner {
    background-image: url(${terms});
    width: 100%;
    height: 9rem;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
  }

  .terms-container {
    list-style-position: inside;
    text-align: justify;
    margin: 5rem auto 3rem;
    max-width: 750px;
    ${screens.md} {
      margin: 3rem 1rem;
    }
  }

  .terms {
    list-style-position: inside;
    line-height: 1.5;

    ol {
      counter-reset: item;
      padding: 0;
    }

    ol > li {
      counter-increment: item;
      margin: 1rem 0;
    }

    ol > li::marker {
      font-size: x-large;
      color: ${colors.primary_2};
      font-weight: 700;
    }

    ol ol > li {
      display: block;
    }

    ol ol > li:before {
      content: counters(item, ".") ". ";
      font-weight: 700;
    }

    ol > li h2 {
      display: inline; /* This makes the h2 inline with the ::before content */
    }
  }

  h1 {
    color: ${colors.text_muted};
    font-size: 2.5rem;
    text-align: start;
  }

  h2 {
    color: ${colors.primary_2};
    margin-top: 5rem;
  }

  h1,
  h2 {
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export { TermsWrapper };
