import { screens } from "_theme/screens";
import styled from "styled-components";

export const DicasMenuWrapper = styled.div`
  margin: 1rem 0 0;
  .title {
    padding: 0.5rem 0;
    font-style: italic;
    text-transform: uppercase;

  }
`;

export const DicasMenuContainer = styled.div`
  display: flex;
  justify-content: space-between; // Isso distribui espaço igualmente entre os itens
  align-items: center; // Isso centraliza os itens verticalmente
  height: 16.25rem;
  gap: 0.75rem;

  ${screens.lg} {
height: auto;
    }
`;

export const MenuItem = styled.div`
  flex-grow: 1;
  // Define a altura das divs
  /* background-color: lightgrey; // Apenas para visualização, você pode mudar conforme necessário */

  a {
    /* position: absolute; */
    /* display: flex;
    padding: 2rem 1.5rem; */
  }
  img {
    height: 100%;
    width: 100%;
  }
`;
