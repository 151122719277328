import React from "react";
import { ButtonContainer } from "./file-button.styles";

import file from "_assets/img/icons/Vector.svg";

const FileButton = (props) => {
  const { children, onClick, preset = "primary" } = props;

  return (
    <ButtonContainer onClick={onClick} preset={preset}>
      {children}
      <img src={file} alt="Upload File" />
    </ButtonContainer>
  );
};

export default FileButton;
