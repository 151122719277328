import { colors } from "_theme/colors";
import styled from "styled-components";

const ProfileButtonWrapper = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;

  .nav-link-profile {
    display: flex;
    align-items: center;
    font-size: medium;
    font-weight: 700;
    text-transform: uppercase;
    gap: 0.5rem;
    border-bottom: 3px solid transparent;

  
    &:hover {
      svg {
        path {
          fill: ${colors.primary_2};
        }
      }
    }

    &.active {
      svg {
        path {
          fill: ${colors.primary_2};
        }
      }
      p {
        color: ${colors.primary_2};
      }
    }
  }
  svg {
    /* margin: auto 0.25rem; */
    width: 2rem;
  }
`;
export { ProfileButtonWrapper };
