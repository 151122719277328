import { useEffect } from 'react';
import { useState } from 'react';

const useTheme = () => {
    const [theme, setTheme] = useState("light");
    const isDarkTheme = theme === "dark";
  
    const toggleTheme = () => {
      const updatedTheme = isDarkTheme ? "light" : "dark";
      setTheme(updatedTheme);
      localStorage.setItem("theme", updatedTheme);
    };
    useEffect(() => {
      const savedTheme = localStorage.getItem("theme");
      const prefersDark = window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (savedTheme && ["dark", "light"].includes(savedTheme)) {
        setTheme(savedTheme);
      } else if (prefersDark) {
        setTheme("dark");
      }
    }, []);
  
    return {
      theme,
      isDarkTheme,
      toggleTheme,
    };
  };
export default useTheme
