import { screens } from "_theme/screens";
import styled from "styled-components";

const BurgerFull = styled.div`
display: none;
  ${screens.lg}{
    display: block;
  }
`

export {BurgerFull}