import React from "react";

import { Link } from "react-router-dom";
import { LinkWrapper } from "./social-links.style";

import { ReactComponent as Facebook } from "_assets/img/coms/facebook-icon.svg";
import { ReactComponent as Instagram } from "_assets/img/coms/instagram-icon.svg";
import { ReactComponent as Linkedin } from "_assets/img/coms/linkedin-icon.svg";
import { ReactComponent as Youtube } from "_assets/img/coms/youtube-icon.svg";
// import {ReactComponent as Email} from '_assets/img/email.svg';

const SocialLinks = () => {
  return (
    <LinkWrapper className="LinkWrapper">
      <Link to="https://www.facebook.com/profile.php?id=100091664635752" target="_blank">
        <Facebook className="icon" />
      </Link>
      {/* <Link to="https://twitter.com/GuerreiroD95378" target='_blank' href="" >
          <Twitter className="icon" />
        </Link> */}
      <Link to="https://instagram.com/medicosnamanga?igshid=OGQ5ZDc2ODk2ZA==" target="_blank">
        <Instagram className="icon" />
      </Link>
      <Link
        to="https://www.linkedin.com/company/m%C3%A9dicos-na-manga/"
        target="_blank"
      >
        <Linkedin className="icon" />
      </Link>
      <Link to="https://www.youtube.com/@medicosnamanga3714" target="_blank">
        <Youtube className="icon" />
      </Link>
      {/* <Link onClick={() => window.location = 'mailto:diogoaguerreiro@gmail.com?subject=Pedido de Informação&body='} >
          <Email className="icon" />
        </Link> */}
    </LinkWrapper>
  );
};

export default SocialLinks;
