import styled from "styled-components";
import { colors } from "_theme/colors";

const ButtonContainer = styled.button`
  background-color: ${(props) => (props.preset === "primary" ? "transparent" : "rgb(142, 142, 142)")};
  color: ${(props) => (props.preset === "primary" ? "rgb(142, 142, 142)" : "#111")};
  border-radius: 0.25rem;
  border: ${colors.light_secondary} 2px solid;
  padding: .25rem .5rem;
  text-align: center;
  font-size: small;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.2s;
  font-weight: 700;

  &:hover {
    /* transform: scale(1.02); */
    box-shadow: 0 0 4px ${colors.text_muted};
  }
`;
export { ButtonContainer };
