import styled from "styled-components";

export const MapsWrapper=styled.div`
height: 100%;

iframe{
    width: 100%;
    height: 100%;
    border: none;
}

`