import { colors } from "_theme/colors";
import styled from "styled-components";

const ContactListWrapper = styled.div`
  .contact-container {
    display: flex;
    flex-direction: row;

    .frame {
      display: flex;
      align-items: center;
      svg {
        path {
          stroke: ${({ theme }) => theme.icon};
        }
      }
    }

    .contact-link {
      :hover {
        svg g {
          path {
            fill: ${colors.primary_2};
          }
        }
      }

      svg {
        path {
          fill: ${({ theme }) => theme.icon};
        }
      }

      .phone,
      .email {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        margin-left: 1rem;
      }
      .phone {
        margin-bottom: 0.25rem;
      }
    }
  }
`;

export { ContactListWrapper };
