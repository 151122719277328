import { colors } from '_theme/colors';
import { screens } from '_theme/screens';
import styled from 'styled-components';

const CategorySelector = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px; 
`;

const CategoryButton = styled.button`
  background: ${({ theme }) => theme.background_emphasis};
  border: 2px solid ${colors.light_border};
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  cursor: pointer;

  ${screens.sm} {
      width: 100%;
    }

  &:hover{
    background-color: ${colors.primary_2};
    border-bottom: none;
    color: white;
  }

  &.active {
    background-color: ${colors.primary_2}; 
    border:2px solid  ${colors.primary_2};
    border-bottom: none;
    color: white;
  }
`;


export {CategoryButton, CategorySelector}