import React from 'react'
import { FaqCardWrapper } from './faq-card.style'

const navigation = [
    {
      name: "Question",
      id: "1",
      alt: "Question",
      span: "What is your product and how does it work?",
      response:
        "	You can customize your order by selecting from a range of flavors, toppings, and add-ins to create your perfect combination.",
    },
    {
      name: "Question",
      id: "2",
      alt: "Question",
      span: "What are the main features of your product?",
      response:
      "	You can customize your order by selecting from a range of flavors, toppings, and add-ins to create your perfect combination.",
    },
    {
      name: "Question",
      id: "3",
      alt: "Question",
      span: "How secure is my data with your product?",
      response:
      "	You can customize your order by selecting from a range of flavors, toppings, and add-ins to create your perfect combination.",
    },
    {
      name: "Question",
      id: "4",
      alt: "Question",
      span: "What kind of customer support do you offer?",
      response:
      "	You can customize your order by selecting from a range of flavors, toppings, and add-ins to create your perfect combination.",
    },
    {
      name: "Question",
      id: "5",
      alt: "Question",
      span: "How much does your product cost?",
      response:
      "	You can customize your order by selecting from a range of flavors, toppings, and add-ins to create your perfect combination.",
    },
    {
      name: "Question",
      id: "6",
      alt: "Question",
      span: "Do you offer a free trial or demo?",
      response:
      "	You can customize your order by selecting from a range of flavors, toppings, and add-ins to create your perfect combination.",
    },
    {
      name: "Question",
      id: "7",
      alt: "Question",
      span: "How do i sign up for your product?",
      response:
      "	You can customize your order by selecting from a range of flavors, toppings, and add-ins to create your perfect combination.",
    },
    {
      name: "Question",
      id: "8",
      alt: "Question",
      span: "Can i cancel my subscription at any time?",
      response:
      "	You can customize your order by selecting from a range of flavors, toppings, and add-ins to create your perfect combination.",
    },
    {
      name: "Question",
      id: "9",
      alt: "Question",
      span: "What are the system requirements for using your product?",
      response:
      "	You can customize your order by selecting from a range of flavors, toppings, and add-ins to create your perfect combination.",
    },
  ];

const FaqCard = () => {
  return (
    <FaqCardWrapper>
<div className="common-container">
        <h2>Index</h2>
        <ul>
          {navigation.map((item) => (
            <li key={item.name}>
              <a href={`#${item.id}`} className="nav-link">
                {item.span}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="common-container">
        <ul>
          {navigation.map((item) => (
            <li key={item.name}>
              <div id={item.id} className="nav-link" >
                <h2>{item.span}</h2>
                <p>{item.response}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </FaqCardWrapper>
  )
}

export default FaqCard