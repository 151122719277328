import styled, { keyframes } from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

const heartBeat = keyframes`
 0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
`;

const Wrapper = styled.div`
  background: linear-gradient(to top, #246db2, #0f7fe5);
  height: 100vh;
  width: 100vw;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  ${screens.md} {
    /* padding: 0.5rem; */
    padding-bottom: 20%;
  }

  .text-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 6rem;
    gap: 3rem;
    z-index: 10;


    p,
    span,
    h1,
    h2 {
      color: ${colors.white};
    }

    ${screens.md} {
      padding: 2rem;
    }

    h1 {
      text-transform: capitalize;
      margin-bottom: 1rem;
      /* font-size: 40px; */
      font-size: 20px;

      ${screens.md} {
        font-size: 32px;
      }
    }
    h2 {
      margin-top: 0;
      /* font-size: 32px; */
      font-size: 16px;
      ${screens.md} {
        font-size: 24px;
      }
    }

    .contact-link {
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: larger;
      transition: all 0.3s;
      align-items: center;
      gap: 0.5rem;
      z-index: 10;

      ${screens.md} {
        flex-direction: column;
      }

      a {
        color: ${colors.text};
        width: fit-content;
      }

      :hover {
        color: ${colors.text};
        /* transform: scale(1.05); */
      }
    }
  }

  .login,
  .banner {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    right: 1rem;
    bottom: 1rem;
    z-index: 10;

    &.banner {
      position: absolute;
      right: 0;
      height: 80%;
      width: 80%;
      z-index: 1;
    }
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    ${screens.lg} {
    top: 30%;
  }
    h2 {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }
    h2,
    p {
      color: ${colors.white};
    }
    &_banner {
      height: 10rem;
      width: 10rem;
      animation: ${heartBeat} 1s ease-in-out infinite;
    }

  }
`;
export { Wrapper };
