import React, { useEffect, useRef, useState } from "react";
import {
  DropDownMenuWrapper,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownListContainer,
  ListItem,
  SearchBox,
} from "./dropdown-menu.style";

const local = [
  { name: "Aveiro", value: "aveiro" },
  { name: "Beja", value: "beja" },
  { name: "Braga", value: "braga" },
  { name: "Bragança", value: "braganca" },
  { name: "Castelo Branco", value: "castelo branco" },
  { name: "Coimbra", value: "coimbra" },
  { name: "Évora", value: "evora" },
  { name: "Faro", value: "faro" },
  { name: "Guarda", value: "guarda" },
  { name: "Leiria", value: "leiria" },
  { name: "Lisboa", value: "lisboa" },
  { name: "Portalegre", value: "portalegre" },
  { name: "Porto", value: "porto" },
  { name: "Santarém", value: "santarem" },
  { name: "Setúbal", value: "setubal" },
  { name: "Viana do Castelo", value: "viana do castelo" },
  { name: "Vila Real", value: "vila real" },
  { name: "Viseu", value: "viseu" },
  // As ilhas:
  { name: "Açores", value: "acores" },
  { name: "Madeira", value: "madeira" }
];

const LocalDropDownMenu = ({ onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const wrapperRef = useRef(null);

  const clearSelection = (event) => {
    event.stopPropagation();
    setSelectedOption(null);
    setSearchTerm("");
  };

  const filteredOptions = local.filter((local) =>
    local.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    setSearchTerm("");
    onChange(value); 
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  return (
    <DropDownMenuWrapper>
    <DropdownContainer ref={wrapperRef} >
      {isOpen ? (
        <SearchBox
          placeholder="Escolha o seu distrito"
          className="form-control"
          id="Search"
          value={searchTerm}
          autoFocus
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      ) : (
        <DropdownHeader onClick={toggling} className="form-control">
            <span>{selectedOption ? selectedOption.name : "Escolha o seu distrito"}</span>
  <span className={selectedOption ? "icon-clear" : `icon-arrow ${isOpen ? 'open' : ''}`} />
  {selectedOption && (
    <span onClick={clearSelection}>X</span>
  )}
        </DropdownHeader>
      )}
      {isOpen && (
        <DropdownListContainer>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((local) => (
              <ListItem onClick={onOptionClicked(local)} key={local.value}>
                {local.name}
              </ListItem>
            ))
          ) : (
            <ListItem>Nenhum distrito encontrado</ListItem>
          )}
        </DropdownListContainer>
      )}
    </DropdownContainer>
  </DropDownMenuWrapper>
  );
};

export default LocalDropDownMenu;
