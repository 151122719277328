import styled from "styled-components";
import { colors } from "_theme/colors";
import banner from "_assets/img/banners/BANNER-Homepage-02.png";
import { screens } from "_theme/screens";

const StaffWrapper = styled.div`
  .staff-banner {
    /* margin-top: -4rem; */
    background-image: url(${banner});
    width: 100%;
    height: 13rem;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;

    h1 {
      font-size: 4rem;
      color: ${colors.white};
      text-transform: uppercase;
      margin: 0 0 0 10%;
    }
  }

  .staff-container {
    /* border: 2px solid red; */
    padding: 2rem 0;
   

    .tab-content{
         display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    background: ${({ theme }) => theme.background_emphasis};
    border-top: 2px solid ${colors.light_border};
    min-height: 26.5rem;

     ${screens.lg} {
      flex-wrap: wrap;
      justify-content: center;
    }
    }
  }

`;

const CategorySelector = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px; // Ajuste conforme necessário
  // Adicione mais estilos conforme necessário
`;

const CategoryButton = styled.button`
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  // Adicione estilos para o botão ativo e hover
`;

export { StaffWrapper, CategorySelector, CategoryButton };
