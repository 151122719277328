import { colors } from "_theme/colors";
import styled from "styled-components";

import banner from "_assets/img/banners/BANNER-Homepage-02.png";

const HealthAgreementWrapper = styled.div`
 

  .agreement-banner{
    /* margin-top: -4rem; */
    background-image: url(${banner});
    width: 100%;
    height: 13rem;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;

    h1{
        font-size: 4rem;
        color:${colors.white};
        text-transform: uppercase;
        margin: 0 0 0 10%;
    }
}

.agreement-container{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2.75rem 13.5rem;
  justify-content: space-evenly;
  padding: 1rem;


  .card-template {
    width: 15rem;
    height: 9.5rem;
    border-radius: 5px;
    box-shadow: 0 0px 6px ${colors.light_border};
    display: flex;
    align-items: center;

  }
  img {
    margin: auto;
  }
}
`;

export { HealthAgreementWrapper };
