import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

const NavWrapper = styled.div`
  display: flex;
  gap: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;

  .nav-link {
    transition: all 0.2s;

    &:hover {
      color: ${colors.primary};
    }
    
  }
.nav-link.active {
      color: ${colors.primary_2};
    }
  ${screens.lg} {
    display: none;
  }
`;
export { NavWrapper };
